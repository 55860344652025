/* eslint-disable no-negated-condition */
/* eslint-disable camelcase */
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Checkbox, FormControl, makeStyles, MenuItem, Select, TextField, Theme, Typography } from '@material-ui/core';
import { CheckCircle, Cancel, RadioButtonUncheckedOutlined } from '@material-ui/icons';
import { ComparableInformation } from './ComparableInformation';
import { CenteredProgress, DropDownButton, TPModal } from '../../../../../components';
import { SplitView } from '../../../../../components/SplitView';
import { selectInitialRangeComparablesWithRejections, selectWorkingContainer } from '../../../../../selectors';
import tokens from '../../../../../styles/designTokens';
import { mediumFont } from '../../../../../styles/fonts';
import { body2, body4, body1, title2, titlelarge } from '../../../../../styles/typography';
import { hasEditAccess } from '../../../../../utils';
import { formatPliDisplayValue, getDisplayFormatAndRounding } from '../../../../../utils/formatPliDisplayValue';
import { GoogleAnalyticsPayload, logGoogleAnalyticsEvent } from '../../../../../utils/sendGoogleAnalyticaEvent';
import { FineTuningBulkActionsCard } from '../../../../FineTuningBulkActionsCard';
import { SingleChipProps, SummaryChips } from '../../../../SummaryChips';
import { TuneCompSearchTab } from '../../../TuneCompSearch.proptype';
import { CompanyTable } from '../../CompanyTable';
import { FineTuningCompaniesProps, RejectionProps, UpdateRejectionStatusParams } from '../FineTuning.proptype';

const useStyles = makeStyles<Theme, RejectionProps>((theme) => ({
  boxSection: {
    height: '100%'
  },
  tableWrapper: {
    height: '100%',
    '& .MuiTableCell-root.MuiTableCell-head': {
      textAlign: 'right'
    },
    '& .MuiTableCell-root.MuiTableCell-body': {
      textAlign: 'right'
    },
    '& .MuiTableCell-root.MuiTableCell-body.right.MuiTableCell-alignRight': {
      paddingRight: '2.25rem'
    }
  },
  headingTitle: {
    ...body4,
    fontSize: 'medium',
    margin: 'auto 0'
  },
  statusDropDown: {
    minWidth: 'none',
    '&.MuiButton-root': {
      minWidth: 0
    }
  },
  statusIcon: {
    ...body1,
    width: '1.375rem',
    height: '1.375rem',
    strokeWidth: '0rem'
  },
  checkCircle: {
    color: '#6E56DB'
  },
  cancelCircle: {
    backgroundColor: 'inherit'
  },
  acceptedCircle: {
    color: '#6E56DB'
  },
  dropDown: {
    ...body1,
    paddingLeft: '0.5rem'
  },
  doneButton: {
    backgroundColor: ({ rejectionType, validOtherRejectionReason }) =>
      (rejectionType === `rejection_type.other` && !validOtherRejectionReason) || rejectionType === ''
        ? ''
        : theme.palette.primary.dark,
    color: theme.palette.primary.light,
    marginLeft: theme.spacing(2),
    '&:hover': {
      background: theme.palette.primary.dark
    }
  },
  rejectionModalContainer: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
    width: '100%'
  },
  rejectionsSelectList: {
    width: '100%',
    marginTop: '1rem'
  },
  rowSelected: {
    '& .MuiTableCell-root': {
      backgroundColor: tokens.product15
    },
    '& td.MuiTableCell-body:first-child': {
      color: tokens.product100,
      borderLeft: `0.188rem solid ${tokens.purpleLight2}`
    }
  },
  truncate: {
    whiteSpace: 'nowrap',
    display: 'block',
    width: '14rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: mediumFont.fontFamily,
    textAlign: 'left'
  },
  country: {
    whiteSpace: 'nowrap',
    display: 'block',
    width: '14rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center'
  },
  sic: {
    whiteSpace: 'nowrap',
    display: 'block',
    width: '14rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center'
  },
  businessDescription: {
    whiteSpace: 'nowrap',
    display: 'block',
    maxWidth: '400px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left'
  },
  rejectionReason: {
    whiteSpace: 'nowrap',
    display: 'block',
    width: '14rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center'
  },
  headerCheckbox: {
    '& .MuiIconButton-label': {
      color: tokens.product100
    },
    '& .MuiIconButton-label:hover': {
      color: tokens.product100
    }
  },
  checkbox: {
    '& .MuiIconButton-label': {
      color: tokens.white
    },
    '& .MuiIconButton-label:hover': {
      color: tokens.product100
    }
  },
  checkboxChecked: {
    '& .MuiIconButton-label': {
      color: tokens.product100,
      visibility: 'visible'
    }
  },
  emptyMessage: {
    ...body2,
    display: 'flex',
    justifyContent: 'center'
  }
}));

export const FineTuning = (companiesData: FineTuningCompaniesProps) => {
  const { t } = useTranslation();

  const [rejectionType, setRejectionType] = useState<string>('');
  const [openRejectionModal, setOpenRejectionModal] = useState<boolean>(false);
  const [otherRejectionReason, setOtherRejectionReason] = useState('');

  const [validOtherRejectionReason, setValidOtherRejectionReason] = useState(true);
  const classes = useStyles({ rejectionType, validOtherRejectionReason });
  const container = useSelector(selectWorkingContainer);

  const initialComparables = useSelector(selectInitialRangeComparablesWithRejections);

  const {
    columns,
    data,
    rejectionReasons,
    updateRejectionStatus,
    fineTuningStatusCounts,
    currentSelectedCompany,
    onClickRow,
    financialInformation,
    pliInfo,
    pliFormat,
    pliId,
    onSetSelectedFilter,
    selectedFilter,
    comparables
  } = companiesData;

  const [tableData, setTableData] = useState(data);
  const [checkedCompanies, setCheckedCompanies] = useState<number[]>([]);
  const [visibleData, setVisibleData] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [allAreSelected, setAllAreSelected] = useState(false);
  const { formatType, roundingValue } = getDisplayFormatAndRounding(pliFormat, pliId);

  const handleSetVisibleData = (data: any) => {
    setVisibleData(data);
  };

  useEffect(() => {
    handleSetVisibleData(data);
  }, [selectedFilter, data]);

  const toggleSelectAll = useCallback(() => {
    void logGoogleAnalyticsEvent({
      event_category: 'pba_button_click',
      event_label: `Fine Tuning status clicked for Select All`,
      container_id: container?.containerId
    });
    const visibleIdsSet = new Set(visibleData.map((row: any) => row.sourceId));
    if (allAreSelected) {
      handleUnselectAll();
    } else {
      const allCheckedItems: any = visibleData.map((row: any) => row.sourceId);
      data.forEach((row: any) => {
        if (visibleIdsSet.has(row.sourceId)) {
          row.checked = true;
        }
      });
      setCheckedCompanies(allCheckedItems);
      setAllAreSelected(true);
    }

    setTableData([...data]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container?.containerId, checkedCompanies, data, visibleData]);

  useEffect(() => {
    setRejectionType(currentSelectedCompany?.rejectionName);
    setOtherRejectionReason(currentSelectedCompany?.otherRejection || '');
  }, [currentSelectedCompany]);

  const handleOnClickRejectionStatus = useCallback(
    (rejectionStatus: string, sourceId: string) => {
      const googleAnalyticsPayload: GoogleAnalyticsPayload = {
        event_category: 'pba_button_click',
        event_label: `Fine Tuning status clicked for ${rejectionStatus}`,
        container_id: container?.containerId
      };

      void logGoogleAnalyticsEvent(googleAnalyticsPayload);

      let isAcceptedAndReviewed = 0;
      let shouldUpdate = false;
      const isRejected = 0;

      switch (rejectionStatus) {
        case 'Accepted':
          isAcceptedAndReviewed = 1;
          shouldUpdate = true;
          break;
        case 'Pending':
          isAcceptedAndReviewed = 0;
          shouldUpdate = true;
          break;
        case 'Rejected':
          setOpenRejectionModal(true);
          break;
        default:
          console.log('Not valid status type');
          break;
      }

      if (shouldUpdate) {
        updateRejectionStatus({
          statuses: {
            isAcceptedAndReviewed,
            isRejected,
            sourceId: Number(sourceId),
            sourceIds: [Number(sourceId)]
          },
          selectedCompaniesSourceId: [Number(sourceId)]
        });
      }
    },
    [container?.containerId, updateRejectionStatus]
  );

  const handleOnClickCheckbox = useCallback(
    (row: any) => {
      const googleAnalyticsPayload: GoogleAnalyticsPayload = {
        event_category: 'pba_button_click',
        event_label: `Fine Tuning status clicked for Status All`,
        container_id: container?.containerId
      };

      void logGoogleAnalyticsEvent(googleAnalyticsPayload);

      row.checked = !row.checked;
      const checkedRows = [...checkedCompanies];
      if (checkedRows.includes(row.sourceId)) {
        checkedRows.splice(checkedRows.indexOf(row.sourceId), 1);
      } else {
        checkedRows.push(row.sourceId);
      }

      setCheckedCompanies(checkedRows);
    },
    [checkedCompanies, container?.containerId]
  );

  const checkBoxTableData = useMemo(() => {
    setTableData(data);

    const tableItems = (row: any) => {
      const { sourceId } = row;
      const theItems = [
        {
          children: (
            <>
              <CheckCircle className={`${classes.checkCircle} ${classes.statusIcon}`} />
              <Typography className={`${classes.dropDown}`}>
                {t(`analysis:comparable-range-accepted-and-reviewed`)}
              </Typography>
            </>
          ),
          onClick: () => {
            handleOnClickRejectionStatus('Accepted', sourceId);
          }
        },
        {
          children: (
            <>
              <RadioButtonUncheckedOutlined className={`${classes.acceptedCircle} ${classes.statusIcon}`} />
              <Typography className={classes.dropDown}>{t(`analysis:comparable-range-accepted`)}</Typography>
            </>
          ),
          onClick: () => {
            handleOnClickRejectionStatus('Pending', sourceId);
          }
        },
        {
          children: (
            <>
              <Cancel className={`${classes.cancelCircle} ${classes.statusIcon}`} />
              <Typography className={classes.dropDown}>{t(`analysis:comparable-range-rejected`)}</Typography>
            </>
          ),
          onClick: () => {
            handleOnClickRejectionStatus('Rejected', sourceId);
          }
        }
      ];

      return theItems;
    };

    return (tableData ?? []).map((row: any) => {
      const comparableStatusRow = (
        <DropDownButton
          disabled={!hasEditAccess()}
          variant="text"
          className={classes.statusDropDown}
          items={tableItems(row)}
        >
          {row?.compStatus === 'global.accepted_and_reviewed' && (
            <CheckCircle className={`${classes.checkCircle} ${classes.statusIcon}`} />
          )}
          {row?.compStatus === 'global.rejected' && (
            <Cancel className={`${classes.cancelCircle} ${classes.statusIcon}`} />
          )}

          {row?.compStatus === 'global.accepted' && (
            <RadioButtonUncheckedOutlined className={`${classes.acceptedCircle} ${classes.statusIcon}`} />
          )}
        </DropDownButton>
      );

      const checboxrow: any = (
        <Checkbox
          className={`${classes.checkbox} ${row.checked ? classes.checkboxChecked : ''}`}
          checked={Boolean(row.checked)}
          value={row.sourceId}
          onClick={() => {
            handleOnClickCheckbox(row);
          }}
        />
      );

      const dataRow: any = {
        sourceId: row.sourceId
      };

      columns.forEach(({ key }: any) => {
        if (typeof row[key] === 'number' && key !== 'sourceId') {
          dataRow[key] = formatPliDisplayValue(formatType, roundingValue, row[key]);
        } else if (typeof row[key] === 'string') {
          dataRow[key] = (
            <span title={row[key]} style={{ ...body2 }} className={classes.truncate}>
              {row[key]}
            </span>
          );
        }
      });

      dataRow.className = row.checked === true ? classes.rowSelected : '';
      dataRow.country = (
        <span title="country" className={classes.country}>
          {row.isoCode}
        </span>
      );

      dataRow.sic = (
        <span title="SIC" className={classes.sic}>
          {row.primaryIndustrialCode}
        </span>
      );

      dataRow.businessDescription = (
        <span title={row.businessDescription} className={classes.businessDescription}>
          {row.businessDescription}
        </span>
      );

      const rejectionName =
        row.rejectionName === 'rejection_type.other' && row.otherRejectionReason
          ? row.otherRejectionReason
          : row.rejectionName
          ? t(`analysis:${String(row.rejectionName)}`)
          : '';
      dataRow.rejectionReason = (
        <span title={rejectionName} className={classes.rejectionReason}>
          {rejectionName}
        </span>
      );

      return {
        ...dataRow,
        rejectionStatus: comparableStatusRow,
        checkbox: checboxrow
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data), JSON.stringify(tableData), columns, allAreSelected]);

  const displayColumns = columns
    ? columns.map((clmn: any) => {
        let displayClmn;
        if (clmn.key === 'compStatus') {
          displayClmn = {
            key: 'rejectionStatus',
            header: 'Status',
            width: '10em'
          };
        } else {
          return clmn;
        }

        return displayClmn;
      })
    : [];

  if (isExpanded) {
    displayColumns.splice(
      1,
      0,
      {
        key: 'sic',
        header: 'SIC'
      },
      {
        key: 'country',
        header: 'Country'
      },
      {
        key: 'businessDescription',
        header: 'Description'
      }
    );
    displayColumns.push({
      key: 'rejectionReason',
      label: 'Rejection Reason'
    });
  }

  displayColumns.push(
    {
      key: 'checkbox',
      header: 'checkbox',
      width: '10em',
      label: <Checkbox className={classes.headerCheckbox} checked={allAreSelected} onChange={toggleSelectAll} />
    },
    {
      key: 'pinned',
      label: ''
    }
  );

  const closeRejectionStatusModal = () => {
    setOpenRejectionModal(false);
    setRejectionType('');
  };

  const ErrorMessage = ({ error, message }: { error: boolean; message: string }) =>
    error ? (
      <Typography color="error" variant="caption">
        {message}
      </Typography>
    ) : null;

  const chipsData: SingleChipProps[] = [
    {
      isSelected: selectedFilter === 'total',
      label: t('analysis:comparable-range-total'),
      amount: fineTuningStatusCounts?.total,
      key: 'total'
    },
    {
      isSelected: selectedFilter === 'acceptedAndReviewed',
      label: t('analysis:filter.company-accepted-and-reviewed'),
      amount: fineTuningStatusCounts?.acceptedAndReviewed,
      key: 'acceptedAndReviewed',
      disabled: !fineTuningStatusCounts?.acceptedAndReviewed
    },
    {
      isSelected: selectedFilter === 'accepted',
      label: t('analysis:filter.company-accepted'),
      amount: fineTuningStatusCounts?.accepted,
      key: 'accepted',
      disabled: !fineTuningStatusCounts?.accepted
    },
    {
      isSelected: selectedFilter === 'rejected',
      label: t('analysis:filter.company-rejected'),
      amount: fineTuningStatusCounts?.rejected,
      key: 'rejected',
      disabled: !fineTuningStatusCounts?.rejected
    }
  ];

  const handleUnselectAll = () => {
    data.forEach((row) => {
      row.checked = false;
    });
    setTableData([...data]);
    setCheckedCompanies([]);
    setAllAreSelected(false);
  };

  const handleBulkStatusAccepted = () => {
    updateRejectionStatus({
      statuses: { isAcceptedAndReviewed: 0, isRejected: 0 },
      selectedCompaniesSourceId: checkedCompanies
    });
    handleUnselectAll();
  };

  const handleBulkStatusAcceptedAndReviewed = () => {
    updateRejectionStatus({
      statuses: { isAcceptedAndReviewed: 1, isRejected: 0 },
      selectedCompaniesSourceId: checkedCompanies
    });
    handleUnselectAll();
  };

  const handleIsExpanded = (isExpanded: boolean) => {
    setIsExpanded(isExpanded);
  };

  return !comparables ? (
    <CenteredProgress />
  ) : columns.length > 0 && data.length > 0 && checkBoxTableData.length > 0 ? (
    <SplitView
      tab={TuneCompSearchTab.FineTuningTab}
      left={
        <div className={classes.tableWrapper}>
          <CompanyTable
            comparables={initialComparables}
            companies={checkBoxTableData}
            columns={displayColumns}
            chips={<SummaryChips chips={chipsData} clickHandler={onSetSelectedFilter} />}
            selectedCompanies={checkedCompanies}
            bulkPanel={
              <FineTuningBulkActionsCard
                selectedComparableCompanyIds={checkedCompanies ?? []}
                unselectAll={() => {
                  handleUnselectAll();
                }}
                onBulkAccepted={() => {
                  handleBulkStatusAccepted();
                }}
                onBulkAcceptedAndReviewed={() => {
                  handleBulkStatusAcceptedAndReviewed();
                }}
                onBulkRejected={() => {
                  setOpenRejectionModal(true);
                }}
              />
            }
            setSortedVisibleData={handleSetVisibleData}
            onRowClick={(event: MouseEvent, index: number, selectedData: any) => {
              onClickRow(Number(selectedData.sourceId));
            }}
          />
          {openRejectionModal && (
            <TPModal
              isOpen
              maxWidth="sm"
              title={
                <div style={{ padding: '2rem 0 0 2rem' }}>
                  <Typography style={{ ...title2 }}>{t(`analysis:comparable-rejection`)}</Typography>
                  <Typography style={{ ...titlelarge }}>
                    {checkedCompanies.length > 0
                      ? t('analysis:fine-tuning-rejection-modal-bulk')
                      : currentSelectedCompany?.companyName ?? ''}
                  </Typography>
                </div>
              }
              actions={[
                {
                  name: t('action-cancel'),
                  handler: () => {
                    closeRejectionStatusModal();
                  }
                },
                {
                  name: t('action-done'),
                  className: classes.doneButton,
                  disabled:
                    (rejectionType === `rejection_type.other` && !validOtherRejectionReason) || rejectionType === '',
                  handler: async () => {
                    void logGoogleAnalyticsEvent({
                      event_category: 'pba_button_click',
                      event_label: `Fine Tuning status updated with Rejected in rejection modal with reason ${rejectionType}`,
                      container_id: container?.containerId
                    });
                    const updateRejectionReasonStatus: boolean =
                      rejectionType !== `rejection_type.other` ||
                      (otherRejectionReason.length > 0 && otherRejectionReason.length < 100);
                    setValidOtherRejectionReason(updateRejectionReasonStatus);
                    if (updateRejectionReasonStatus) {
                      const updateRejectionStatusData: UpdateRejectionStatusParams = {
                        statuses: { isAcceptedAndReviewed: 0, isRejected: 1 },
                        rejectionType,
                        otherRejectionReason
                      };
                      if (checkedCompanies.length > 0) {
                        updateRejectionStatusData.selectedCompaniesSourceId = checkedCompanies;
                      }

                      updateRejectionStatus(updateRejectionStatusData);

                      closeRejectionStatusModal();
                      setOtherRejectionReason('');
                      if (checkedCompanies.length > 0) handleUnselectAll();
                    }
                  }
                }
              ]}
              onClose={() => {
                closeRejectionStatusModal();
              }}
            >
              <div>
                <FormControl size="small" variant="outlined">
                  <div className={classes.rejectionModalContainer}>
                    <Typography style={{ ...title2 }}>{t(`analysis:comparable-rejection-reason`)}</Typography>
                    <Select
                      className={classes.rejectionsSelectList}
                      value={rejectionType ?? ''}
                      onChange={($event) => {
                        setRejectionType(`${String($event.target.value)}`);
                      }}
                    >
                      {(rejectionReasons ?? []).map(({ rejectionTypeId, rejectionName }: any) => (
                        <MenuItem key={rejectionTypeId} value={rejectionName}>
                          {t(`analysis:${String(rejectionName)}`)}
                        </MenuItem>
                      ))}
                    </Select>
                    {rejectionType === `rejection_type.other` && (
                      <div style={{ paddingTop: '2rem' }}>
                        <TextField
                          fullWidth
                          size="medium"
                          placeholder="Add rejection reason here"
                          variant="outlined"
                          error={rejectionType === `rejection_type.other` && !validOtherRejectionReason}
                          defaultValue=""
                          value={otherRejectionReason}
                          onChange={({ target: { value } }) => {
                            setOtherRejectionReason(value);
                            setValidOtherRejectionReason(true);
                          }}
                        />
                        <ErrorMessage
                          error={Boolean(rejectionType === `rejection_type.other` && !validOtherRejectionReason)}
                          message="You must enter a rejection reason and it must be within 100 characters."
                        />
                      </div>
                    )}
                  </div>
                </FormControl>
              </div>
            </TPModal>
          )}
        </div>
      }
      right={
        <ComparableInformation
          company={currentSelectedCompany}
          financialInfo={financialInformation}
          pliInfo={pliInfo}
          pliFormat={pliFormat}
          pliId={pliId}
        />
      }
      className={classes.boxSection}
      handleIsExpanded={handleIsExpanded}
    />
  ) : (
    <Typography className={classes.emptyMessage}>{t(`analysis:no-companies-rejected-bulk`)}</Typography>
  );
};
